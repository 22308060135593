import './App.css';
import * as Sentry from "@sentry/react";
import { useEffect, useState, useRef, useCallback } from 'react';
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { CSSTransition } from 'react-transition-group';
import { IconButton, Orb, RotatingText } from './CustomComps';
import supabase from './Auth';
import Modal from '@mui/material/Modal';
import LoadingIcon from './loading.svg';

import vocalPause1 from './vocal_pauses/vp1.wav';
import vocalPause2 from './vocal_pauses/vp2.wav';
import vocalPause3 from './vocal_pauses/vp3.wav';

const USE_CASE_SUGGESTIONS = [
  "Try asking Martin, \"What's the latest on the Russia-Ukraine war?\"",
  "Try asking Martin, \"Can you reserve a table for 2 at Outback tonight?\"",
  "Try asking Martin, \"Can you remind me to get groceries after dinner.\"",
  "Try telling Martin to \"Order a Domino's pizza to my house by 7pm.\"",
  "Try telling Martin to \"Find me a tennis class on Saturday in SF.\"",
  "Try talking to Martin about your favorite sports team.",
  "Try talking to Martin about a movie you’ve watched recently.",
  "Try brainstorming ideas for your next vacation with Martin.",
  "Try brainstorming ideas for a creative project with Martin."
];


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Main({ jwt, firstLogin }) {
  const [isActive, setIsActive] = useState(false);
  const [orbState, setOrbState] = useState("default");
  const isActualStop = useRef(false);

  const [deactivateDisabled, setDeactivateDisabled] = useState(false);
  const [activateDisabled, setActivateDisabled] = useState(false);

  const [error, setError] = useState(null);

  let recognizer = useRef(null);
  let curAudio = useRef(null);
  let connection = useRef(null);

  let curAudioEnded = useRef(false);

  const [notes, setNotes] = useState(null);

  const [centralMessage, setCentralMessage] = useState("Click to activate Martin."); 

  const [micPermitted, setMicPermitted] = useState(false);

  const [activeCompsVisible, setActiveCompsVisible] = useState(false);
  const [inactiveCompsVisible, setInactiveCompsVisible] = useState(false);

  const [orbAnimating, setOrbAnimating] = useState(false);
  const [orbAnimateState, setOrbAnimateState] = useState(false);

  const [hasBeenSilent, setHasBeenSilent] = useState(false);
  const [messageBuffer, setMessageBuffer] = useState([]);

  const [helpModalOpen, setHelpModalOpen] = useState(firstLogin);

  const [WSOpen, setWSOpen] = useState(false);

  const [integrationsModalOpen, setIntegrationsModalOpen] = useState(false);

  const [calendarState, setCalendarState] = useState("");

  const showCompsActive = useCallback(() => { 
    setTimeout(() => {
      requestAnimationFrame(() => {
        setActiveCompsVisible(true)
        setActivateDisabled(false);
      });
    }, 500); 
  }, []);
  
  const showCompsInactive = useCallback(() => { setInactiveCompsVisible(true) }, []);


  useEffect(() => { Sentry.captureException(error) }, [error]);
  

  const stopRecognizer= useCallback(() => {
    isActualStop.current = true;
    recognizer.current.stop();

    setOrbAnimating(false);
    setTimeout(() => {setOrbAnimateState(false);}, 400);
  }, []);

  const startRecognizer = useCallback(() => {
    isActualStop.current = false;
    recognizer.current.start();

    setOrbAnimating(false);
    setTimeout(() => {setOrbAnimateState(false);}, 400);
  }, []);


  const startListening = useCallback(() => {
    if (recognizer.current) {
      startRecognizer();
      setIsActive(true);
      setOrbState("default");
    }
    else {
      console.log("ERROR: No recognizer");
      setError("No recognizer");
    }
  
    curAudio.current = null;
  }, [startRecognizer]);

  const stopListening = useCallback(() => {
    if (recognizer.current) {
      stopRecognizer();
      setIsActive(false);
      setOrbState("default");
    }
    else {
      console.log("ERROR: No recognizer");
      setError("No recognizer");
    }

    curAudio.current?.pause();
    curAudio.current = null;
  }, [stopRecognizer]);

  const sendMessage = useCallback((message, sendInstant=false) => {
    if (connection.current) {
      if (connection.current.readyState === WebSocket.OPEN) {
        if (sendInstant) {

          if(message.toLowerCase().includes("martin") && message.toLowerCase().includes("goodbye")) {
            stopListening();
            setOrbState("thinking");
          }
          else {
            stopRecognizer();
            setOrbState("thinking");  
          }

          console.log("Sending message to server: " + message);
          connection.current.send(message);
        }
        else {
          console.log("Queuing up message: " + message);
          setMessageBuffer((messageBuf) => { return [...messageBuf, message]; });
        }
      } else {
        setError('Cannot send message, WebSocket is not open');
      }
    } else {
      setError('Cannot send message, no WebSocket connection');
    }
  }, [stopListening, stopRecognizer]);

  useEffect(() => {
    console.log((hasBeenSilent ? "has been silent" : "has not been silent") + " and message buffer length is " + messageBuffer.length);
    if (hasBeenSilent && messageBuffer.length > 0) {
      let message = messageBuffer.join(" ");
      setMessageBuffer([]);

      if(message.toLowerCase().includes("martin") && message.toLowerCase().includes("goodbye")) {
        stopListening();
        setOrbState("thinking");
      }
      else {
        stopRecognizer();
        setOrbState("thinking");  
      }

      console.log("Sending message to server: " + message);
      connection.current.send(message);

      let vocalPauses = [vocalPause1, vocalPause3];

      // get number of spaces in message
      let numSpaces = message.split(" ").length - 1;
      if (numSpaces > 10) vocalPauses.push(vocalPause2);
      
      let vocalPause = vocalPauses[Math.floor(Math.random() * vocalPauses.length)];

      if (numSpaces > 3) {
        curAudio.current?.pause();

        let audio = new Audio(vocalPause);
        audio.autoplay = true;
        audio.play().then(() => {
          curAudioEnded.current = false;
        });

        audio.onended = () => {
          curAudioEnded.current = true;
        }

        curAudio.current = audio;
      }
    }
  }, [messageBuffer, hasBeenSilent, stopListening, stopRecognizer]);

  const getWebSocketURL = useCallback(() => {
    let loc = process.env.REACT_APP_API_URL;

    return "wss://" + loc + '/ws?token=' + jwt;
  // ~~~~ Disabling warning since jwt is constant ~~~~
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCurAudioEnded = useCallback(() => {
    curAudioEnded.current = true;
    if (isActive) {
      if (recognizer.current) {
        startRecognizer();
        setOrbState("default");
      }
      else setError("No recognizer");
    }
    else {
      setActiveCompsVisible(false);
      setDeactivateDisabled(false);
    }
  }, [isActive, startRecognizer]);


  const handleServerMessage = useCallback((event) => {
    console.log("WEBSOCKET: recieved message from server")

    let message = event.data;

    if (typeof message === "string") {
      setNotes(message);
    }
    else {
      let audioData = new Uint8Array(message);
      let blob = new Blob([audioData], { type: 'audio/wav' });
      let url = URL.createObjectURL(blob);

      let timeout = curAudioEnded.current ? 1 : 1000;

      setTimeout(() => {
        if (curAudio.current) curAudio.current.pause();

        let audio = new Audio(url);

        audio.autoplay = true;
        audio.play().then(() => {
          setOrbState("speaking");
          curAudioEnded.current = false;
        });
        audio.onended = handleCurAudioEnded;

        curAudio.current = audio;
      }, timeout);
    }
  }, [handleCurAudioEnded]);

  // Update current websocket's onmessage function to handle incoming messages
  // whenever the handleServerMessage function changes (when isActive changes).
  useEffect(() => {
    if (connection.current) connection.current.onmessage = handleServerMessage;
  }, [handleServerMessage]);

  useEffect(() => {
    if (curAudio.current) curAudio.current.onended = handleCurAudioEnded;
  }, [handleCurAudioEnded]);

  const initWebSocket = useCallback(() => {
    if (connection.current && connection.current.readyState === WebSocket.OPEN ) connection.current.close();

    let ws = new WebSocket(getWebSocketURL());

    ws.onopen = () => {
        console.log('WEBSOCKET: Connection opened');
        setWSOpen(true);
    };

    ws.onclose = () => { setWSOpen(false); };

    ws.onerror = (event) => {
      console.log('WEBSOCKET: Error -- ', event); 
      setError('Connection Error');
    }

    ws.binaryType = 'arraybuffer';

    connection.current = ws;
  }, [getWebSocketURL]);

  const initRecognizer = useCallback(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();
  
    recognition.interimResults = false;
    recognition.continuous = true;
  
    recognition.onresult = (event) => {
      let message = event.results[event.results.length - 1][0].transcript
      console.log('RECOGNIZER: words detected -- ' + message);
      sendMessage(message);
    };
  
    recognition.onsoundstart = () => { 
      console.log('RECOGNIZER: started hearing sound');
    }
    recognition.onsoundend = () => { 
      console.log('RECOGNIZER: stopped hearing sound');
    }
    recognition.onstart = () => { console.log('RECOGNIZER: activated'); }
    recognition.onend = () => { 
      console.log('RECOGNIZER: deactivated'); 
      if (!isActualStop.current) {
        startRecognizer();
      }
    }

    recognizer.current = recognition;

    stopRecognizer();
  }, [sendMessage, stopRecognizer, startRecognizer]);

  // ON PAGELOAD
  useEffect(() => {
    // set up websocket connection on pageload
    initWebSocket();


    // load in start screen on pageload
    setInactiveCompsVisible(true);

    // set up speech recognition on pageload
    navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false
}).then((stream) => {
    setMicPermitted(true);
    initRecognizer();

    let audioContext = new (window.AudioContext || window.webkitAudioContext)();
    let analyser = audioContext.createAnalyser();
    const source = audioContext.createMediaStreamSource(stream);
    source.connect(analyser);

    analyser.fftSize = 2048;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    let THRESH = 15; // Set your threshold value
    let MIN_TIME = 1000; // Minimum time below threshold in milliseconds

    // Initial state
    let isActivated = false;
    let lastAboveThresh = null;

    let lastNAmplitudes = [];
    let hasBeenSilentL = false;

    const update = () => {
        analyser.getByteFrequencyData(dataArray);
        let amplitude = dataArray.reduce((a, b) => a + b, 0) / bufferLength;

        lastNAmplitudes.push(amplitude);

        if (lastNAmplitudes.length > 20) lastNAmplitudes.shift();

        // check if all of the last N amplitudes are below the threshold, if so setHasBeenSilent(true)
        if (lastNAmplitudes.every((amp) => amp < THRESH)) {
          if (!hasBeenSilentL) {
            setHasBeenSilent(true);
            hasBeenSilentL = true;
            // console.log("!! has been silent !!")
          }
        }
        else {
          if(hasBeenSilentL) {
            setHasBeenSilent(false);
            hasBeenSilentL = false;
            // console.log("!! has not been silent !!")
          }
        }

        if (amplitude > THRESH) {
            if (!isActivated) {
                setOrbAnimateState(true);
                setTimeout(() => {setOrbAnimating(true);}, 400);
                isActivated = true;
                // console.log("detecting speech");
            }
            lastAboveThresh = new Date();
        } else {
            if (isActivated) {
                let now = new Date();
                if (lastAboveThresh && now - lastAboveThresh > MIN_TIME) {
                    setOrbAnimating(false);
                    setTimeout(() => {setOrbAnimateState(false);}, 400);
                    isActivated = false;
                    // console.log("not detecting speech");
                }
            }
        }
        setTimeout(() => {requestAnimationFrame(update)}, 100);
    };

    update();
    }).catch(function(error) {
      console.log(error);
      setMicPermitted(false);
      setCentralMessage("Please allow microphone access to use Martin.");

      let intervalId = setInterval(() => {
        navigator.permissions.query({name: 'microphone'}).then((result) => {
          if (result.state === 'granted') {
            // refresh the page
            clearInterval(intervalId);
            console.log("Reloading page");
            window.location.reload(true);
            setMicPermitted(true);
          }
        });
      }, 500);
    });

    // close websocket connection on page close
    return () => {
      console.log("Closing connection")
      if (connection.current.readyState === WebSocket.OPEN ) connection.current.close();
    };
    // ~~~~ Disabling warning since effect should only run on pageload ~~~~
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getNotesFilename() {
    var today = new Date();
    var date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
    return "notes-" + date + ".txt";
  }

  function downloadNotes(e) {
    e.stopPropagation();
    if (notes) {
      const element = document.createElement("a");
      const file = new Blob([notes], {type: 'text/plain'});
      element.href = URL.createObjectURL(file);
      element.download = getNotesFilename();
      document.body.appendChild(element);
      element.click();
    }
    else {
      setError('No Notes to Download');
    }
  }


  const onClickActivate = useCallback(() => {
    console.log(WSOpen)
    if (micPermitted && !activateDisabled && WSOpen) {
      setInactiveCompsVisible(false);
      setActivateDisabled(true);
      startListening();
      sendMessage("Hello Martin", true);
    }

    if (!WSOpen) {
      setError('Failed to connect. Please wait and try again.');
    }
  }, [micPermitted, startListening, activateDisabled, sendMessage, WSOpen])

  const onClickDeactivate = useCallback(() => {
    sendMessage("Goodbye Martin");
    setDeactivateDisabled(true);
  }, [sendMessage]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    let integration = searchParams.get('integration');
    let code = searchParams.get('code');
    let state = searchParams.get('state');

    console.log(integration, state, code)

    if (integration) {
      setIntegrationsModalOpen(true);
      if (integration === "calendar") {
        if (code && state) {
          // make request to /auth/calendar/callback with code=code as a URL param
          fetch('https://' + process.env.REACT_APP_API_URL + '/auth/calendar/callback?code=' + code + "&state=" + state).then(
            response => response.json()
          ).then(data => {
            if (data["message"] === "success") window.location.href = window.location.origin + "/?integration=calendar";
            else setError("An error occurred. Please try again.")
          }).catch((error) => {
            console.log(error);
          });
        }
        else {
          updateCalendarStatus();
        }
      }
    }
    else {
      updateCalendarStatus();
    }
  }, []); 

  const updateCalendarStatus = useCallback(() => {
    fetch('https://' + process.env.REACT_APP_API_URL + '/auth/calendar/status?token=' + jwt).then(
      response => response.json()
    ).then(data => {
      if (data["status"]) setCalendarState(data["status"])
    }).catch((error) => {
      console.log("INTEGRATIONS ERROR: " + error);
    });
  }, []);

  const toggleCalendar = useCallback(() => {
    if (calendarState === "Disabled") {
      // make request to backend at /auth/calendar/ with token=jwt as a URL param
      fetch('https://' + process.env.REACT_APP_API_URL + '/auth/calendar?token=' + jwt).then(
        response => response.json()
      ).then(data => {
        if (data["url"]) window.location.href = data["url"];
        else setError("An error occurred. Please try again.")
      }).catch((error) => {
        console.log(error);
      });
    }
    else if (calendarState === "Enabled") {
      // calendar is enabled, so disable it 
      // make request to backend at /auth/calendar/disable with token=jwt as a URL param
      fetch('https://' + process.env.REACT_APP_API_URL + '/auth/calendar/delete?token=' + jwt).then(
        response => response.json()
      ).then(data => {
        if (data["success"]) setCalendarState("Disabled")
        else setError("An error occurred. Please try again.")
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [calendarState]);

  const requestCalendarAccess = useCallback(() => {
    // make request to backend at /auth/calendar/test with token=jwt as a URL param
    fetch('https://' + process.env.REACT_APP_API_URL + '/auth/calendar/test?token=' + jwt).then(
      response => response.json()
    ).then(data => {
      if (data["status"]) setCalendarState(data["status"]);
      else setError("An error occurred. Please try again.")
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  return (
    <div id="main">
        <div id="header">
            <button id={"feedback-btn"} className={"text-button"} onClick={()=>{
              window.open("https://forms.gle/qzv97HNGHKYghn6A8", "_blank");
            }}>
                Give Feedback
            </button>
            <button id={"integrations-btn"} className={"text-button"} onClick={()=>{
              setIntegrationsModalOpen((prev) => !prev); 
            }}>
                Integrations
            </button>
            <div id="hf-spacer"></div>
            <CSSTransition
              in={activeCompsVisible}
              timeout={400}
              classNames="fade"
              unmountOnExit
              onExited={showCompsInactive}
            >
              <button id={"deactivate-btn"} className={"text-button"} onClick={onClickDeactivate} disabled={deactivateDisabled}>
                { !deactivateDisabled ? "Deactivate Martin" : <img src={LoadingIcon} className={"loading-icon"} alt={''} /> }
              </button>
            </CSSTransition>
            {/* sign out button */}
            <IconButton onClick={() => {
              supabase.auth.signOut();
            }} style={{marginRight: '-15px'}}>
              {/* <i className={"fa-solid fa-user"}></i> */}
              <i className="fa-solid fa-arrow-right-from-bracket"></i>
            </IconButton>
        </div>
        <div id="content">
            <CSSTransition
                in={activeCompsVisible}
                timeout={400}
                classNames="fade"
                unmountOnExit
                onExited={showCompsInactive}
            >
              <div style={{width: '100%', height: '15vh'}}></div>
            </CSSTransition>
            <CSSTransition
              in={activeCompsVisible}
              timeout={500}
              classNames="scale"
              unmountOnExit
              onExited={showCompsInactive}
            >
              <Orb orbState={orbState} animating={orbAnimating} stateChange={orbAnimateState}/>
            </CSSTransition>
            <CSSTransition
              in={activeCompsVisible}
              timeout={400}
              classNames="fade"
              unmountOnExit
              onExited={showCompsInactive}
            >
              <div id={"sub-orb-cont"}>
                <IconButton 
                  onClick={() => {curAudio.current.currentTime += 100000000}} 
                  solid={true}
                  style={{ transform: 'scale(1.3)' }}
                  hidden={orbState !== 'speaking'}
                >
                  <i className={"fa-solid fa-stop"}></i>
                </IconButton>
                {/* <span id="sub-orb-instruction">Say "Martin" in your sentence to engage him.</span> */}
                <RotatingText texts={USE_CASE_SUGGESTIONS} interval={4000} id={"use-cases-rotating"}></RotatingText>
              </div>
            </CSSTransition>
            <CSSTransition
              in={inactiveCompsVisible}
              timeout={400}
              classNames="fade"
              unmountOnExit
              onExited={showCompsActive}
            >
              <div id="central-message-cont" onClick={onClickActivate}>
                <span id='central-message'>{centralMessage}</span>
                <button id="download-button" hidden={!notes} onClick={downloadNotes}>
                  Conversation Notes &nbsp; <i className="fa-solid fa-download"></i>
                </button>
              </div>
            </CSSTransition>
        </div>
        <div id="footer">
            <div id="hf-spacer"></div>
            <IconButton 
                onClick={ () => { setHelpModalOpen(true) } }
                solid={true}
              >
                <i className={"fa-solid fa-question"}></i>
              </IconButton>
        </div>
        <Snackbar
          anchorOrigin={{vertical:'bottom', horizontal:'left'}}
          open={error !== null}
          autoHideDuration={1000}
          onClose={() => setError(null)}
        >
          <Alert severity="error">{error}</Alert>
        </Snackbar>
        <CSSTransition
          in={integrationsModalOpen}
          timeout={400}
          classNames="fade"
          unmountOnExit
        >
          <Modal
            style={{display:'flex', alignItems:'center', justifyContent:'center'}}
            open={integrationsModalOpen}
            onClose={() => {setIntegrationsModalOpen(false)}}
          >
            <div className={"modal"} id="integrations-modal">
              <span id="integrations-modal-title">Integrations</span>
              <div id="integrations-modal-cards">
                <div className={"integrations-card"}>
                    <span className={"integrations-card-title"}>Google Calendar</span>
                    {
                      calendarState === "No Access" ?
                      <button 
                      className={"integrations-card-button"} 
                      onClick={requestCalendarAccess}>
                        Request Access
                      </button> :
                       (
                        calendarState === "Requested Access" ?
                        <span id="integrations-card-waiting-txt">Waiting for Access</span> :
                        (
                          calendarState === "Enabled" ?
                          <button 
                          className={"integrations-card-button integrations-card-btn-enabled"} 
                          onClick={toggleCalendar}>
                            Disable
                          </button> :
                          (
                            calendarState === "Disabled" ?
                            <button 
                            className={"integrations-card-button"} 
                            onClick={toggleCalendar}>
                              Enable
                            </button> 
                            :  <div></div>
                          )
                        )
                       )
                    }
                </div>
              </div>
            </div>
          </Modal>
        </CSSTransition>
        <CSSTransition
          in={helpModalOpen}
          timeout={400}
          classNames="fade"
          unmountOnExit
        >
          <Modal
            style={{display:'flex', alignItems:'center', justifyContent:'center'}}
            open={helpModalOpen}
            onClose={() => {setHelpModalOpen(false)}}
          >
            <div className={"modal"} id="help-modal">
              <span id="help-modal-title">Meet Martin, your new-age voice assistant.</span>
              <p id="help-modal-text">
                Say “Goodbye Martin” to end the conversation.<br/><br/>
                With your permission, Martin can: <br/>
                - Search for news, places near you, weather, etc. on Google <br/>
                - Make phone calls to restaurants and hair salons to book appointments              
              </p>
              <div id="modal-start-btn-cont"> 
                <button className={"text-button"} id={"modal-start-btn"} onClick={() => {setHelpModalOpen(false)}}>
                  Get Started with Martin &nbsp; <i className={"fa-solid fa-arrow-right"}></i>
                </button>
              </div>
            </div>
          </Modal>
        </CSSTransition>
    </div>
  );
}

export default Main;
