import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

Sentry.init({
  dsn: "https://03455a23c19840839013bc97ad740b5b@o4505361183801344.ingest.sentry.io/4505497934233600",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", "trymartin.com"],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 0.1, 
  replaysSessionSampleRate: 0.1, 
  replaysOnErrorSampleRate: 1.0, 
});

function LoadFromHTML({url}) {
  const [data, setData] = useState("");
  useEffect(() => {
    fetch(url)
      .then(response => response.text())
      .then(contents => setData(contents))
      .catch(console.error);
  }, []);
  return (
    <div dangerouslySetInnerHTML={{ __html: data }} />
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/privacy" element={<LoadFromHTML url='privacy.html' />} />
      <Route path="/tos" element={<LoadFromHTML url='tos.html' />} />
      <Route path="/data" element={<LoadFromHTML url='data_deletion.html' />} />
      <Route path="/:integration?/:state?/:code?/" element={<App />} />
    </Routes>
  </BrowserRouter>
);

reportWebVitals();
