import React from 'react';
import PropTypes from 'prop-types';
import LoadingIcon from './loading.svg';
import { useState, useEffect } from "react";

const Orb = ({orbState, stateChange, animating}) => {

    return (
        <div className={"orb-cont"}>
            <div 
                className={"orb orb-inner orb-" + orbState}
            >
                {orbState === "thinking" ? <img src={LoadingIcon} className={"loading-icon"} alt={''}/> : null }
            </div>
            <div 
                className={
                    "orb orb-outer-1"
                    + (stateChange && orbState === "default" ? " orb-outer-1-animate-state-change " : " ")
                    + (animating && orbState === "default" ? "orb-outer-1-animate" : "")
                }
            ></div>
            <div
                className={
                    "orb orb-outer-2" 
                    + (stateChange && orbState === "default" ? " orb-outer-2-animate-state-change " : " ")
                    + (animating && orbState === "default" ? "orb-outer-2-animate" : "")
                }
            ></div>
        </div>
    );
  }
  
  
const IconButton = ({children, onClick, solid=false, disabled=false, loading=false, style={}, hidden=false}) => {
    let buttonClasses = "icon-button " + (solid ? "icon-button-filled" : "icon-button-unfilled")

    if(loading || hidden) disabled = true;

    let handleClick = () => {
        if(!disabled) onClick();
    }
    
    return (
        <div 
            className={buttonClasses + (hidden ? " icon-button-invis" : "")} 
            onClick={handleClick} 
            style={style}
        >
            {children}
            {/* {loading ? <img src={LoadingIcon} className={"loading-icon"} alt={''} /> : children} */}
        </div>
    )
}

IconButton.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    solid: PropTypes.bool,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    style: PropTypes.object,
    hidden: PropTypes.bool
}


const RotatingText = ({ texts, interval, id }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, interval);
    
    return () => {
      clearInterval(timer);
    };
  }, [texts, interval]);

  return <div id={id}>{texts[index]}</div>;
};

export { Orb, IconButton, RotatingText };