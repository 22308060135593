import './index.css'
import { useState, useEffect } from 'react'
import Main from './Main'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import supabase from './Auth';
import logo from './logo.svg';
import { isMobile, browserName } from 'react-device-detect';

// TODO: update all colors in theme
const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#dedede',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#fffffb',
      main: '#f7f7f3'
      // dark: will be calculated from palette.secondary.main,
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

// function fallbackRender({ error, resetErrorBoundary }) {
//   // Call resetErrorBoundary() to reset the error boundary and retry the render.

//   return (
//     <div role="alert">
//       <p>Something went wrong:</p>
//       <pre style={{ color: "red" }}>{error.message}</pre>
//     </div>
//   );
// }

export default function App() {
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
      setLoading(false);
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
    

    return () => subscription.unsubscribe()
  }, [])

  if (loading) {
    return (<div></div>);
  }

  if (!session) {
    return (
      <div id={"auth-cont"}>
        <div id={"auth-logo-cont"}>
          <img id={"auth-logo"} src={logo} alt={"Martin"} />
        </div>
        <div id={"auth-main-cont"}>
          <button className={"auth-button"} onClick={
              async () => {
                
                const { data, error } = await supabase.auth.signInWithOAuth({
                  provider: 'google',
                  options: {
                    redirectTo: window.location.origin
                  }
                })

                if (error) console.log(error)
                else console.log(data)
              }
            }>
              <i class="fa-brands fa-google"></i> &nbsp; Sign in with Google
            </button>
        </div>
      </div>
    )
  }
  else {
    let firstLogin;

    // check localStorage for first login key
    if (localStorage.getItem('firstLogin') === null) {
      localStorage.setItem('firstLogin', 'true');
      firstLogin = true;
    }
    else {
      localStorage.setItem('firstLogin', 'false');
      firstLogin = false;
    }

    if(isMobile) {
      return (<div id="unavailable-cont">Martin is currently unavailable on Mobile devices.</div>)
    }

    // check if user is on safari
    if(browserName === "Safari") {
      return (<div id="unavailable-cont">Martin is currently unavailable on Safari.</div>)
    }

    return (
        <ThemeProvider theme={theme}>
          <Main jwt={session.access_token} firstLogin={firstLogin} />
        </ThemeProvider>
    )
  }
}